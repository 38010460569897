(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/dress-with-preliminary-row/assets/javascripts/dress-with-prel-row.js') >= 0) return;  svs.modules.push('/components/marketplace/dress-with-preliminary-row/assets/javascripts/dress-with-prel-row.js');

'use strict';

const {
  getProductClassName
} = svs.utils.products;
const {
  selectors
} = svs.components.marketplaceData;
const {
  CouponQueryParams,
  convertSignsTo1X2String,
  convertSignsToAntmalString,
  convertSignsToResultString
} = svs.components.sport.tipsenShared;
const {
  BOMBEN,
  FULLTRAFF,
  RACING
} = svs.utils.products.productIds;
const dressRacingPrelRow = (state, activatedDrawId, gameUrl) => {
  var _state$BetProposal$ac;
  const rows = (_state$BetProposal$ac = state.BetProposal[activatedDrawId]) === null || _state$BetProposal$ac === void 0 ? void 0 : _state$BetProposal$ac.data;
  if (!rows || Object.keys(rows).length === 0) {
    return gameUrl;
  }
  const mapSelectionToBitValue = {
    1: 1,
    2: 2,
    3: 4,
    4: 8,
    5: 16
  };
  const maxKey = Math.max(...Object.keys(rows).map(Number));
  let res = '';
  for (let horseNumber = 1; horseNumber <= maxKey; horseNumber++) {
    if (rows[horseNumber]) {
      let rowRes = 0;
      Object.keys(rows[horseNumber]).forEach(selection => {
        if (rows[horseNumber][selection]) {
          rowRes += mapSelectionToBitValue[selection];
        }
      });
      if (horseNumber === 1) {
        res = String(rowRes);
      } else {
        res += ",".concat(rowRes);
      }
    } else if (horseNumber > 1) {
      res += ",";
    }
  }
  return "".concat(gameUrl, "&row=").concat(res);
};
const dressBombPrelRow = (state, activatedDrawId, gameUrl) => {
  var _state$BetProposal$ac2;
  const rows = (_state$BetProposal$ac2 = state.BetProposal[activatedDrawId]) === null || _state$BetProposal$ac2 === void 0 ? void 0 : _state$BetProposal$ac2.data;
  if (!rows || Object.keys(rows).length === 0 || !Array.isArray(Object.values(rows)[0])) {
    return gameUrl;
  }
  let result = "".concat(gameUrl, "&").concat(CouponQueryParams.PARAM_SIGNS, "=");
  const _turnRowOfChoicesIntoBitMaskNumber = choices => {
    let sum = 0;
    choices.forEach((isSelected, index) => {
      if (isSelected) {
        sum += Math.pow(2, index);
      }
    });
    return sum;
  };
  const convertedRows = Object.values(rows).map(eventGroup => eventGroup.map(eventGroupSigns => [_turnRowOfChoicesIntoBitMaskNumber(eventGroupSigns)]));
  result += convertSignsToResultString(convertedRows);
  return result;
};
const convertRowObjectsToArray = rows => Object.values(rows).map(row => {
  const rowValues = Object.values(row);
  const mapped = rowValues.map(b => b ? 1 : 0);
  return mapped;
});
const dressFulltraffPrelRow = (state, activatedDrawId, gameUrl) => {
  var _state$BetProposal$ac3;
  const rows = (_state$BetProposal$ac3 = state.BetProposal[activatedDrawId]) === null || _state$BetProposal$ac3 === void 0 ? void 0 : _state$BetProposal$ac3.data;
  if (!rows || Object.keys(rows).length === 0) {
    return gameUrl;
  }
  const signMatrix = convertRowObjectsToArray(rows);
  const antmalString = convertSignsToAntmalString(signMatrix || []);
  const result = "".concat(gameUrl, "&").concat(CouponQueryParams.PARAM_SIGNS, "=").concat(antmalString);
  return result;
};
function dressWithPreliminaryRow(state, activatedDrawId, productId, drawNumber, gameUrl) {
  var _state$BetProposal$ac4;
  if (productId === RACING) {
    return dressRacingPrelRow(state, activatedDrawId, gameUrl);
  }
  if (productId === BOMBEN) {
    return dressBombPrelRow(state, activatedDrawId, gameUrl);
  }
  if (productId === FULLTRAFF) {
    return dressFulltraffPrelRow(state, activatedDrawId, gameUrl);
  }
  const drawEvents = selectors.draws.getDrawEvents(state, {
    drawNumber,
    productName: getProductClassName(productId)
  });
  let urlWithRow = gameUrl;
  const row = (_state$BetProposal$ac4 = state.BetProposal[activatedDrawId]) === null || _state$BetProposal$ac4 === void 0 ? void 0 : _state$BetProposal$ac4.data;
  if (row && drawEvents) {
    const signs = [];
    for (let eventNumber = 1; eventNumber <= drawEvents.length; eventNumber++) {
      signs.push(row[eventNumber] ? Object.values(row[eventNumber]) : []);
    }
    const urlSigns = convertSignsTo1X2String(signs);
    if (urlSigns.length > 0) {
      urlWithRow += "&".concat(CouponQueryParams.PARAM_SIGNS, "=").concat(urlSigns);
    }
  }
  return urlWithRow;
}

setGlobal('svs.components.marketplace.dressWithPreliminaryRow', {
  dressRacingPrelRow,
  dressFulltraffPrelRow,
  dressWithPreliminaryRow,
  dressBombPrelRow
});

 })(window);